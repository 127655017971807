import React from "react";
import styled, { css } from "styled-components";
import { useHistory } from "react-router-dom";
import withResponsive from "/Shared/helpers/withResponsive";

export const BaseButtonStyle = css`
	font-family: ${({ theme }) => theme.typography.fonts.secondary};
	position: relative;
	display: inline-flex;
	align-items: center;
	justify-content: center;
	text-decoration: none;
	transition: all 0.2s ease-in-out;

	${({ disabled }) =>
		disabled &&
		css`
			& {
				background: ${({ theme }) =>
					theme.buttons.disabled.background} !important;
				color: ${({ theme }) => theme.buttons.disabled.text};
				border: none;
				cursor: not-allowed;
			}
		`};
`;

const ButtonStyle = withResponsive(styled.button`
	${BaseButtonStyle};
	border-radius: ${({ theme }) => theme.spacing[10]};
	text-transform: uppercase;
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings.widest};
	font-weight: ${({ theme }) => theme.typography.fontWeights.bold};

	font-size: ${({ theme }) => theme.typography.fontSizes.md};
	padding: ${({ theme }) => `${theme.spacing[2.5]} ${theme.spacing.default}`};
	min-width: ${({ theme, fullWidth }) =>
		fullWidth ? `100%` : theme.spacing[40]};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.lg};
		padding: ${({ theme }) => `${theme.spacing[2.5]} ${theme.spacing.default}`};
		min-width: ${({ theme, fullWidth }) =>
			fullWidth ? `100%` : theme.spacing[40]};
	}

	background-color: ${({ theme, primary, white, disabled }) =>
		!disabled
			? primary
				? theme.buttons.primary.background
				: white
				? theme.buttons.white.background
				: theme.buttons.secondary.background
			: theme.buttons.disabled.background};
	color: ${({ theme, primary, white, disabled }) =>
		!disabled
			? primary
				? theme.buttons.primary.color
				: white
				? theme.buttons.white.color
				: theme.buttons.secondary.color
			: theme.buttons.disabled.color};
	border: ${({ theme, primary, white, disabled }) =>
		!disabled
			? primary
				? `2px solid ${theme.buttons.primary.border}`
				: white
				? `1px solid ${theme.buttons.white.border}`
				: `1px solid ${theme.buttons.secondary.border}`
			: theme.buttons.disabled.border};

	&:hover {
		background-color: ${({ theme, primary, white, disabled }) =>
			!disabled
				? primary
					? theme.buttons.primary.hover.background
					: white
					? theme.buttons.white.hover.background
					: theme.buttons.secondary.hover.background
				: theme.buttons.disabled.background};
		color: ${({ theme, primary, white, disabled }) =>
			!disabled
				? primary
					? theme.buttons.primary.hover.color
					: white
					? theme.buttons.white.hover.color
					: theme.buttons.secondary.hover.color
				: theme.buttons.disabled.color};
		border: ${({ theme, primary, white, disabled }) =>
			!disabled
				? primary
					? `2px solid ${theme.buttons.primary.hover.border}`
					: white
					? `1px solid ${theme.buttons.white.hover.border}`
					: `1px solid ${theme.buttons.secondary.hover.border}`
				: "none"};
	}
`);

const Button = ({
	children,
	disabled,
	link,
	as,
	isAlternativeLink = false,
	isExternal = false,
	...rest
}) => {
	const { push } = useHistory();

	const handleClick = () => {
		if (link) {
			if (isAlternativeLink) {
				if (isExternal) {
					window.open(link);
				} else {
					window.location.href = link;
				}
			} else {
				push(link);
			}
		}
	};

	return (
		<ButtonStyle
			onClick={handleClick}
			as={disabled ? "button" : as}
			disabled={disabled}
			{...rest}
		>
			{children}
		</ButtonStyle>
	);
};

export default Button;
