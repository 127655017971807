const breakpoints = {
	base: 0,
	xs: "20em", // 320px
	sm: "30em", // 480px
	md: "48em", // 768px
	lg: "62em", // 992px
	xl: "80em", // 1280px
	"2xl": "95em", // 1520px
	maxWidth: "104em", // 1664px
};

export const breakpointsForResponsiveProps = [
	breakpoints.xs,
	breakpoints.sm,
	breakpoints.md,
	breakpoints.lg,
	breakpoints.xl,
	breakpoints["2xl"],
	breakpoints["wide"],
];

export default breakpoints;
