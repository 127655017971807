import styled, { css } from "styled-components";
import { ifProp, theme, prop } from "styled-tools";
import withResponsive from "/Shared/helpers/withResponsive";

const ParagraphStyles = css`
	font-size: ${props => props.theme.typography.fontSizes.md};
	line-height: 150%;
	text-align: left;
	font-family: ${props => props.theme.typography.fonts.primary};

	color: ${ifProp(
		{ color: "white" },
		theme("colors.white"),
		theme("colors.primary")
	)};

	${({ type }) =>
		type == "preamble" &&
		css`
			font-size: 18px;
			line-height: 30px;
			margin-top: 2rem;

			@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
				max-width: 90%;
			}
		`}

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		max-width: ${prop("max-width")};
	}
`;

const Paragraph = styled.p`
	${ParagraphStyles}
`;

export default withResponsive(Paragraph);
