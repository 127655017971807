import styled from "styled-components";
import withResponsive from "shared/helpers/withResponsive";

const MaxWidthContainer = styled.div`
	width: 100%;
	max-width: ${({ theme, narrow }) =>
		narrow ? theme.spacing.siteWidthNarrow : theme.spacing.siteWidth};
	margin: 0 auto;
	padding: ${({ theme, noGutters }) =>
		noGutters ? undefined : `0 ${theme.spacing.siteMarginMobile}`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme, noGutters }) =>
			noGutters ? undefined : `0 ${theme.spacing.siteMarginTablet}`};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding: ${({ theme, noGutters }) =>
			noGutters ? undefined : `0 ${theme.spacing.siteMargin}`};
	}

	& & {
		padding: 0;
	}
`;

export default withResponsive(MaxWidthContainer);
