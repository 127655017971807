import { useState, useEffect } from "react";
import throttle from "lodash.throttle";

const getDeviceConfig = width => {
	if (width < 320) {
		return "xsmall";
	} else if (width >= 320 && width < 768) {
		return "small";
	} else if (width >= 768 && width < 992) {
		return "medium";
	} else if (width >= 992 && width < 1280) {
		return "large";
	} else if (width >= 1280 && width < 1520) {
		return "xlarge";
	} else if (width >= 1520 && width < 1664) {
		return "xxlarge";
	} else if (width >= 1664) {
		return "maxWidth";
	}
};

const breakpointsStrings = [
	"xsmall",
	"small",
	"medium",
	"large",
	"xlarge",
	"xxlarge",
	"maxWidth",
];

const useBreakpoint = (breakpointToCompare, behaviour = "") => {
	const [brkPnt, setBrkPnt] = useState(() =>
		getDeviceConfig(window.innerWidth)
	);

	useEffect(() => {
		const calcInnerWidth = throttle(function () {
			setBrkPnt(getDeviceConfig(window.innerWidth));
		}, 200);
		window.addEventListener("resize", calcInnerWidth);
		return () => window.removeEventListener("resize", calcInnerWidth);
	}, []);

	if (behaviour === "") {
		return breakpointToCompare === brkPnt;
	}

	if (behaviour !== "") {
		if (breakpointToCompare === brkPnt) {
			return true;
		} else {
			const currentIndex = breakpointsStrings.indexOf(brkPnt);
			const compareIndex = breakpointsStrings.indexOf(breakpointToCompare);

			if (behaviour === "down") {
				return currentIndex < compareIndex;
			}

			if (behaviour === "up") {
				return currentIndex > compareIndex;
			}
		}
	}

	return false;
};

export default useBreakpoint;
