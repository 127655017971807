import React from "react";
import styled, { withTheme } from "styled-components";

const Decoration = ({ theme, small = false, big = false }) => {
	const size = small
		? theme.spacing[2]
		: big
		? theme.spacing[3]
		: theme.spacing[2.5];
	return (
		<DecorationWrapper>
			<DecorationBall color={`${theme.colors.blue}c4`} size={size} />
			<DecorationBall color={`${theme.colors.pink}c4`} size={size} />
			<DecorationBall color={`${theme.colors.blue}c4`} size={size} />
			<DecorationBall color={`${theme.colors.primary}c4`} size={size} />
		</DecorationWrapper>
	);
};

export default withTheme(Decoration);

const DecorationWrapper = styled.div`
	display: flex;
	flex-direction: row;
	gap: ${({ theme }) => theme.spacing[1.5]};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		gap: ${({ theme }) => theme.spacing[2]};
	}
`;
const DecorationBall = styled.div`
	border-radius: 100%;
	background-color: ${({ color }) => color};
	height: ${({ theme }) => theme.spacing[2]};
	width: ${({ theme }) => theme.spacing[2]};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		height: ${({ size }) => size};
		width: ${({ size }) => size};
	}
`;
