import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { useParams } from "react-router-dom";

import { unsubscribe, fetchStandardPage } from "api";

import { BaseLayout } from "Layouts";
import { MaxWidthContainer, InnerContainer, Spacer } from "Shared/layout";
import { RTEContent, PageHeader } from "BaseComponents";
import { Seo } from "Components";
import { Image } from "Shared/helpers/getModelTypes";
import Footer from "../Modules/footer/Footer";
import Page404 from "./Page404";

const Standardpage = ({ pages = [] }) => {
	const [loaded, setLoaded] = useState(false);
	const [standardPage, setStandardPage] = useState(null);
	const [show404, set404] = useState(false);

	const { slug } = useParams();

	useEffect(() => {
		setLoaded(false);
		fetchStandardPage(slug).subscribe(response => {
			if (!!response?.items?.length) {
				setStandardPage(response.items[0]);
			} else {
				set404(true);
			}
		});

		return () => unsubscribe();
	}, [slug]);

	useEffect(() => {
		if (!!standardPage || show404) {
			setLoaded(true);
		}
	}, [standardPage, show404]);

	if (!loaded) return false;

	const {
		header = {},
		text_ef2ee8b: text = {},
		text: preamble = {},
		image = {},
		theme = {},
		button___text = {},
		button___link = {},
		button___alternative_link = {},
	} = standardPage;

	if (show404) {
		return <Page404 url={slug} />;
	}

	const standardImage = Image(image);
	const pageHeaderButton = {
		name: button___text?.value,
		url: !!button___link?.value?.length
			? `/${button___link?.value[0]?.url?.value}`
			: "/",
		alternative: button___alternative_link?.value,
	};

	return (
		<>
			<Seo
				header={header?.value}
				preamble={preamble?.value}
				image={standardImage?.src}
			/>
			<BaseLayout pages={pages} themeVariant={theme?.value[0]?.codename}>
				<StandardPageContentWrapper className="page page--standard">
					<PageHeader
						header={header?.value}
						preamble={preamble?.value}
						image={image}
						button={pageHeaderButton}
					/>
					<MaxWidthContainer>
						<Spacer
							spacing={{
								base: !!standardImage?.src ? 0 : 6,
								md: !!standardImage?.src ? 0 : 10,
							}}
						>
							<InnerContainer noGutters narrow>
								<TextWrapper>
									{text?.value ? (
										<RTEContent
											className="kentico-content"
											content={text.value}
										/>
									) : null}
								</TextWrapper>
							</InnerContainer>
						</Spacer>
					</MaxWidthContainer>
					<Footer />
				</StandardPageContentWrapper>
			</BaseLayout>
		</>
	);
};

export default Standardpage;

const StandardPageContentWrapper = styled.div`
	background: ${({ theme }) =>
		`linear-gradient(180deg, ${theme.colors.white} 45.74%, ${theme.colors.grey} 57.37%)`};
`;

const TextWrapper = styled.div`
	flex-basis: 50%;
	flex-grow: 1;
	padding: ${({ theme }) => `${theme.spacing.default} 0;`};
`;
