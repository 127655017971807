import React from "react";
import Parser from "html-react-parser";

import { Spacer } from "Shared/layout";
import { Decoration, Button } from "BaseComponents";

export const tinyToReact = () => {
	return {
		replace: domNode => {
			const { name, type, children } = domNode;
			const switchOn = name || type;
			switch (switchOn) {
				case "p":
					const codeChild = children.filter(child => child.name === "code");
					if (!!codeChild[0]?.children?.length) {
						const parsed = Parser(codeChild[0]?.children[0]?.data);
						const { props } = parsed;
						const type = props["data-type"];
						switch (type) {
							case "decoration":
								return (
									<SpacingForDomElm>
										<Decoration />
									</SpacingForDomElm>
								);
							case "button":
								return (
									<SpacingForDomElm>
										<Button primary link={props["data-link"]}>
											{props["data-text"]}
										</Button>
									</SpacingForDomElm>
								);
							default:
								break;
						}
					}
				default:
					return domNode;
			}
		},
	};
};

const SpacingForDomElm = ({ children }) => {
	return (
		<Spacer
			spacing={{
				base: 6,
				md: 8,
			}}
		>
			{children}
			<Spacer
				spacing={{
					base: 6,
					md: 8,
				}}
				direction="bottom"
			/>
		</Spacer>
	);
};
