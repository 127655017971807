import styled from "styled-components";

export default styled.p`
	font-size: ${({ theme }) => theme.typography.fontSizes.md};
	font-family: ${({ theme }) => theme.typography.fonts.primary};
	line-height: 150%;
	text-align: left;
	letter-spacing: ${({ theme }) => theme.typography.letterSpacings.label};
	color: ${({ theme }) => theme.colors.label};
	text-transform: uppercase;
	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
`;
