import { colors } from "../Shared/core";

const blueTheme = {
	colors: {
		background: "#024c71",
	},
	buttons: {
		ghostButton: {
			color: colors.white,
			background: "rgb(255 255 255 / 15%)",
			hover: {
				background: "rgb(255 255 255 / 85%)",
				color: colors.ebonyClay,
			},
		},
	},
	header: {
		background: {
			default: "#024c71",
			scrolled: "#024c71",
		},
	},
};

export default blueTheme;
