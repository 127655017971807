const colors = {
	sunglo: "#E56B70",
	nightShadz: "#C33C54",
	ebonyClay: "#222939",
	ebonyClayDarker10: "#101625",
	bunker: "#131B1F",
	trout: "#4A505D",
	allPorts: "#0471A6",
	porcelain: "#F7F9F9",
	gallery: "#F0F0F0",
	concrete: "#F2F2F2",
	silver: "#c1c1c1",
	darkGrey: "#949494",
	grey: "#a7a7a7",
	black: "#000000",
	white: "#FFFFFF",
};

export default colors;
