import { createGlobalStyle } from "styled-components";
import _normalize from "./_normalize";

const GlobalStyle = createGlobalStyle`
    ${_normalize};

    body {
        background-color: ${({ theme }) => theme.colors.background};
        font-family: ${({ theme }) => theme.typography.fonts.primary};
        font-size: ${({ theme }) => theme.typography.fontSizes.md};
        font-style: normal;
        font-weight: ${({ theme }) => theme.typography.fontWeights.normal};
        color: ${({ theme }) => theme.colors.text};
        line-height: ${({ theme }) => theme.typography.lineHeights.base};
    }

    html, body { overflow-x: hidden; }

    a {
      color: inherit;
      line-height: inherit;
    }
`;

export default GlobalStyle;
