import React from "react";
import { Helmet } from "react-helmet";

const Seo = ({ header = "", preamble = "", image = "", url = "" }) => {
	const metaHeader = header ? `Ependy - ${header}` : "Ependy";

	return (
		<Helmet>
			<title>{metaHeader}</title>
			{preamble && <meta name="description" content={preamble} />}
			<meta property="og:title" content={metaHeader} />
			<meta property="og:url" content={url || window.location.href} />
			{preamble && <meta property="og:description" content={preamble} />}
			{/* {image && <meta property="og:image" content={image} />} */}
			{/* OG:IMAGE IS HARD CODED IN INDEX:HTML */}
		</Helmet>
	);
};
export default Seo;
