import styled, { css } from "styled-components";

export const LinkStyles = css`
	font-size: ${({ theme }) => theme.typography.fontSizes.md};
	font-family: ${({ theme }) => theme.typography.fonts.primary};
	color: ${({ theme, light }) =>
		light ? theme.link.light.color : theme.link.color};
	line-height: 150%;
	display: flex;
	text-decoration: underline;
	transition: all 0.3s ease-in-out;

	font-weight: ${({ bold }) => (bold ? "bold" : "normal")};
	text-transform: ${({ upperCase }) => (upperCase ? "uppercase" : "normal")};
	letter-spacing: ${({ letterSpacing }) => letterSpacing && letterSpacing};

	&:hover,
	&:focus {
		color: ${({ theme, light }) =>
			light ? theme.link.light.hover : theme.link.hover};
	}
`;

const Link = styled.a`
	${LinkStyles};
`;

export default Link;
