import React from "react";
import { ThemeProvider, ThemeContext } from "styled-components";
import { useContext } from "react";

import { theme, blueTheme, pinkTheme } from "Themes";

export const MapTheme = ({ theme: newTheme, children }) => {
	const currentTheme = useContext(ThemeContext);

	const mapPropToTheme = (themeKey = "") => {
		switch (themeKey.toLowerCase()) {
			case "blue":
				return blueTheme;
			case "pink":
				return pinkTheme;
			default:
				return theme;
		}
	};
	return (
		<ThemeProvider theme={mergeThemes(currentTheme, mapPropToTheme(newTheme))}>
			{children}
		</ThemeProvider>
	);
};

const merge = (old, cur) => {
	const res = { ...old };
	for (const key of Object.keys(cur)) {
		const val = cur[key];
		if (
			res[key] === null ||
			typeof val !== "object" ||
			typeof res[key] !== "object" ||
			Array.isArray(val)
		) {
			res[key] = val;
		} else {
			res[key] = merge(res[key], val);
		}
	}
	return res;
};

const mergeThemes =
	(...themes) =>
	parent => {
		for (const theme of themes) {
			parent = merge(parent, theme);
		}
		return parent;
	};
