import React from "react";
import styled, { withTheme } from "styled-components";
import { useHistory } from "react-router-dom";

import { Spacer, MaxWidthContainer, InnerContainer } from "Shared/layout";
import { handleClick } from "Shared/helpers";
import { Image } from "Shared/helpers/getModelTypes";
import { H1, Preamble, Button, Image as LazyLoadImage } from "BaseComponents";

const PageHeader = ({
	header = "",
	preamble = "",
	image,
	button = {},
	background = "blue",
	theme = {},
}) => {
	const { push } = useHistory();
	const standardImage = Image(image);

	return (
		<Top hasImage={!!standardImage?.src} background={background}>
			<MaxWidthContainer>
				<InnerContainer noGutters narrow center>
					<H1 color={theme.colors.grey}>{header}</H1>
					<Spacer spacing={{ base: 4.5, md: 10 }}>
						<Preamble white>{preamble}</Preamble>
					</Spacer>
					{button?.name && (button?.url || button?.alternative) && (
						<Spacer spacing={{ base: 4.5, md: 10 }}>
							<Button
								white
								onClick={e =>
									handleClick(e, push, button?.url, button?.alternative)
								}
							>
								{button?.name}
							</Button>
						</Spacer>
					)}
				</InnerContainer>
				{!!standardImage?.src ? (
					<Spacer spacing={{ base: 4.5, md: 10 }}>
						<ImageWrapper>
							<LazyLoadImage image={standardImage} />
						</ImageWrapper>
					</Spacer>
				) : (
					<Spacer direction="bottom" spacing={{ base: 8, md: 10 }} />
				)}
			</MaxWidthContainer>
		</Top>
	);
};

export default withTheme(PageHeader);

const Top = styled.div`
	padding: ${({ theme }) => `${theme.spacing[8]} 0 0 0`};

	background: ${({ theme, hasImage }) =>
		hasImage
			? `linear-gradient(${theme.pageHeader.top.background.from} 30%, ${theme.pageHeader.top.background.to} 67%, white 35%)`
			: `linear-gradient(${theme.pageHeader.top.background.from} 50%, ${theme.pageHeader.top.background.to})`};};
	color: ${({ theme }) => theme.colors.white};
	position: relative;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme, hasImage }) =>
			hasImage
				? `${theme.spacing[20]} ${theme.spacing[10]} ${theme.spacing[8]} ${theme.spacing[10]}`
				: `${theme.spacing[20]} ${theme.spacing[10]} ${theme.spacing[16]} ${theme.spacing[10]}`}} ;
	}
`;

const ImageWrapper = styled(Spacer)`
	position: relative;
	padding: ${({ theme }) => `${theme.spacing[8]} 0`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		aspect-ratio: 16/9;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`;
