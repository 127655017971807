import React, { useState, useEffect, useRef } from "react";
import styled from "styled-components";

import {
	animated,
	useTransition,
	useSpring,
	useChain,
	config,
} from "react-spring";
import { useHistory } from "react-router-dom";

import { handleClick } from "Shared/helpers";
import { Spacer } from "Shared/layout";
import { Decoration } from "BaseComponents";

export default ({ pages = [] }) => {
	const [open, toggleMenu] = useState(false);
	const navRef = useRef();
	const liRef = useRef();
	const [menuItems, setMenuItems] = useState([]);
	const { push } = useHistory();

	useEffect(() => {
		setMenuItems(
			pages?.map(({ name, url }, i) => ({
				id: i,
				name: name?.value,
				link: url?.value,
			}))
		);
	}, [pages]);

	const springProps = useSpring({
		ref: navRef,
		config: config.default,
		from: { width: "0%" },
		to: { width: open ? "100%" : "0%" },
	});

	const liTransitions = useTransition(
		open ? menuItems : [],
		item => item.name,
		{
			ref: liRef,
			trail: 400 / menuItems.length,
			from: { opacity: 0, transform: "translateY(20px)" },
			enter: { opacity: 1, transform: "translateY(0)" },
			leave: { opacity: 0, transform: "translateY(20px)" },
		}
	);

	useChain(open ? [navRef, liRef] : [liRef, navRef], [0, open ? 0.4 : 0.6]);

	return (
		<>
			<StyledBurger open={open} onClick={() => toggleMenu(!open)}>
				<div />
				<div />
				<div />
			</StyledBurger>
			<Nav className="menu" style={springProps}>
				<ul>
					{liTransitions?.map(({ item = {}, key, props }, index) => (
						<Li key={item.id + 1} style={props}>
							<MenuAnchor
								onClick={e => handleClick(e, push, item?.link)}
								href={item.link}
							>
								{item.name}
							</MenuAnchor>
							{index + 1 !== liTransitions.length && (
								<Spacer>
									<Decoration />
								</Spacer>
							)}
						</Li>
					))}
				</ul>
			</Nav>
		</>
	);
};

const StyledBurger = styled.button`
	position: relative;
	display: flex;
	flex-direction: column;
	justify-content: space-around;
	width: ${({ theme }) => theme.spacing[6]};
	height: ${({ theme }) => theme.spacing[6]};
	background: transparent;
	border: none;
	cursor: pointer;
	padding: 0;
	z-index: 10;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		width: ${({ theme }) => theme.spacing[7]};
		height: ${({ theme }) => theme.spacing[7]};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		width: ${({ theme }) => theme.spacing[8]};
		height: ${({ theme }) => theme.spacing[8]};
	}

	&:focus {
		outline: none;
	}

	div {
		width: 100%;
		height: 0.25rem;
		border-radius: ${({ theme }) => theme.spacing[2.5]};
		transition: all 0.3s linear;
		position: relative;
		transform-origin: 1px;
		background: ${props => props.theme.colors.pink};

		@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		}

		:first-child {
			transform: ${({ open }) => (open ? "rotate(45deg)" : "rotate(0)")};
		}

		:nth-child(2) {
			opacity: ${({ open }) => (open ? "0" : "1")};
			transform: ${({ open }) => (open ? "translateX(20px)" : "translateX(0)")};
			background: ${props => props.theme.colors.blue};
		}

		:nth-child(3) {
			transform: ${({ open }) => (open ? "rotate(-45deg)" : "rotate(0)")};
			background: ${({ theme, open }) =>
				open ? theme.colors.pink : theme.colors.primary};
		}
	}
`;

const Nav = styled(animated.nav)`
	position: absolute;
	background: linear-gradient(180deg, #860f24 0%, rgb(195 60 84 / 90%) 100%);
	height: 100vh;
	display: flex;
	justify-content: center;
	align-items: center;
	top: 0;
	left: 0;

	ul {
		margin: 0;
		padding: 0;
	}
`;

const Li = styled(animated.li)`
	list-style: none;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-direction: column;

	& + & {
		margin-top: ${({ theme }) => theme.spacing.default};
	}
`;

const MenuAnchor = styled.a`
	color: ${({ theme }) => theme.colors.lightGrey};
	font-size: ${({ theme }) => theme.spacing[6]};
	text-align: center;
	text-transform: uppercase;
	text-decoration: none;
	opacity: 0.8;
	line-height: 1.3;
	font-family: ${({ theme }) => theme.typography.fonts.secondary};
	transition: all 0.3s ease-in-out;

	&:hover {
		color: ${({ theme }) => theme.colors.white};
		transform: scale(1.05);
		opacity: 1;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: ${({ theme }) => theme.spacing[10]};
	}
`;
