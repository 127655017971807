import { client } from "../Shared/config";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

let unsubscribeSubject = new Subject();

export const unsubscribe = () => {
	unsubscribeSubject.next();
	unsubscribeSubject.complete();
};

const fetchPages = (type, depth = 1) => {
	return client
		.items()
		.type(type)
		.depthParameter(depth)
		.toObservable()
		.pipe(takeUntil(unsubscribeSubject));
};

export const fetchStartPage = () => {
	return fetchPages("startpage");
};

export const fetchSettingsPage = () => {
	return fetchPages("settingspage");
};

export const fetchStandardPage = (slug = "") => {
	return client
		.items()
		.type("standardpage")
		.equalsFilter("elements.url", slug)
		.toObservable();
};
