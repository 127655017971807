import React from "react";
import styled from "styled-components";
import Parser from "html-react-parser";
import { useHistory } from "react-router-dom";

import { tinyToReact } from "Shared/helpers/RTEDomHelper";

import {
	H1Styles,
	H2Styles,
	H3Styles,
	H4Styles,
	LinkStyles,
	ParagraphStyles,
} from "BaseComponents";

const RTEContentElement = styled.div`
	h1,
	h2,
	h3,
	h4 {
		@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
			max-width: 80%;
		}
	}
	h1 {
		${H1Styles};

		& + * {
			padding-top: ${({ theme }) => theme.spacing.default};
			@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
				padding-top: ${({ theme }) => theme.spacing[6]};
			}
		}
	}
	h2 {
		${H2Styles};

		& + * {
			padding-top: ${({ theme }) => theme.spacing.default};
			@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
				padding-top: ${({ theme }) => theme.spacing[6]};
			}
		}
	}
	h3 {
		${H3Styles};
		& + * {
			padding-top: ${({ theme }) => theme.spacing.default};
		}
	}
	h4 {
		${H4Styles};
		& + * {
			padding-top: ${({ theme }) => theme.spacing.default};
		}
	}

	b,
	strong {
		font-weight: bold;
	}

	p {
		${ParagraphStyles}
		& + * {
			padding-top: ${({ theme }) => theme.spacing.default};
			@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
				padding-top: ${({ theme }) => theme.spacing[6]};
			}
		}
	}

	a {
		${LinkStyles};
		display: inline-flex;
	}

	i,
	em {
		font-style: italic;
	}

	figure {
		margin: 0;
	}
	img {
		width: 100%;
		height: auto;
	}

	ol,
	ul {
		font-family: ${({ theme }) => theme.typography.fonts.secondary};
		font-weight: 600;
		color: ${({ theme }) => theme.colors.primary};
		font-size: ${({ theme }) => theme.typography.fontSizes.lg};
		padding-top: ${({ theme }) => theme.spacing[6]};
		padding-bottom: ${({ theme }) => theme.spacing[6]};

		@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
			padding-top: ${({ theme }) => theme.spacing[8]};
			padding-bottom: ${({ theme }) => theme.spacing[8]};
		}
	}
`;

const RTEContent = props => {
	const { content } = props;
	const parsedContent = Parser(content, tinyToReact());
	const { push } = useHistory();

	const handleAnchorClick = e => {
		const targetLink = e.target.closest("a");
		if (!targetLink) return;

		const urlObject = new URL(targetLink);
		const urlOrigin = urlObject?.origin || "";
		const currentOrigin = window?.location?.origin;
		const isInternalLink = urlOrigin === currentOrigin;
		const href = targetLink?.href || "";

		if (isInternalLink && href) {
			e.preventDefault();
			push(href);
		}
	};

	return (
		<RTEContentElement
			{...props}
			onClick={handleAnchorClick}
			onKeyPress={handleAnchorClick}
		>
			{parsedContent}
		</RTEContentElement>
	);
};

export default RTEContent;
