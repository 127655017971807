import React from "react";
import styled from "styled-components";

import withResponsive from "/Shared/helpers/withResponsive";

import { BaseButtonStyle } from "./Button";

const GhostButtonStyle = withResponsive(styled.button`
	${BaseButtonStyle};

	&& {
		background: ${({ theme }) => theme.buttons.ghostButton.background};
		border-radius: ${({ theme }) => theme.spacing[1.5]};
		min-width: ${({ theme }) => theme.spacing[20]};
		font-size: ${({ theme }) => theme.typography.fontSizes.sm};
		font-weight: ${({ theme }) => theme.typography.fontWeights.bold};
		color: ${({ theme }) => theme.buttons.ghostButton.color};
		padding: ${({ theme }) => `${theme.spacing[1]} ${theme.spacing[2]}`};
		transition: all 0.3s ease-in-out;
		backdrop-filter: ${({ theme }) => `blur(${theme.spacing[1.2]})`};

		@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
			min-width: ${({ theme }) => theme.spacing[30]};
			font-size: ${({ theme }) => theme.typography.fontSizes.sm};
			padding: ${({ theme }) =>
				`${theme.spacing[1.5]} ${theme.spacing.default}`};
		}

		&:hover {
			background: ${({ theme }) => theme.buttons.ghostButton.hover.background};
			color: ${({ theme }) => theme.buttons.ghostButton.hover.color};
		}
	}
`);

const GhostButton = ({ children, disabled, as, ...rest }) => {
	return (
		<GhostButtonStyle
			as={disabled ? "button" : as}
			disabled={disabled}
			{...rest}
		>
			{children}
		</GhostButtonStyle>
	);
};

export default GhostButton;
