import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { theme } from "styled-tools";
import { useHistory } from "react-router-dom";

import { unsubscribe, fetchSettingsPage } from "api";

import { handleClick } from "Shared/helpers";
import {
	MaxWidthContainer,
	InnerContainer,
	ColumnContainer,
	Column,
} from "/Shared/layout";
import { Link, H4 } from "/BaseComponents";

import Logo from "/Images/full-logo-white.png";

const Footer = ({ theme }) => {
	const [footerData, setFooterData] = useState({});
	const { push } = useHistory();

	useEffect(() => {
		fetchSettingsPage().subscribe(({ firstItem }) => {
			let footerLinks = firstItem?.links?.value?.map(link => {
				return {
					name: link?.name?.value,
					url: link?.url?.value ? `/${link?.url?.value}` : "",
				};
			});
			setFooterData({
				first: {
					header: firstItem?.first_column_header?.value,
					firstRow: firstItem?.first_column_first_row?.value,
					secondRow: firstItem?.first_column_second_row?.value,
					thirdRow: firstItem?.first_column_third_row?.value,
				},
				second: {
					header: firstItem?.link_column_header?.value,
					links: footerLinks,
				},
				third: {
					header: firstItem?.third_column_header?.value,
					mail: firstItem?.mail?.value,
					phone: firstItem?.phone_number?.value,
				},
			});
		});

		return () => unsubscribe();
	}, []);

	if (!footerData) return null;

	const { first = {}, second = {}, third = {} } = footerData;

	return (
		<FooterSection className="footer">
			<MaxWidthContainer>
				<Wrapper wide>
					<LogoWrapperWhite
						src={Logo}
						onClick={e => handleClick(e, push, "/")}
					/>
					<ColumnContainer
						flex-direction={{ base: "row", md: "row" }}
						justify-content={{ md: "space-around" }}
						gap={theme.spacing.default}
					>
						{first && (
							<List>
								{first?.header && (
									<ListItem>
										<TextSectionHeader>{first?.header}</TextSectionHeader>
									</ListItem>
								)}
								{first?.firstRow && <ListItem>{first?.firstRow}</ListItem>}
								{first?.secondRow && <ListItem>{first?.secondRow}</ListItem>}
								{first?.thirdRow && <ListItem>{first?.thirdRow}</ListItem>}
							</List>
						)}
						{second && (
							<List>
								{second?.header && (
									<ListItem>
										<TextSectionHeader>{second?.header}</TextSectionHeader>
									</ListItem>
								)}
								{second?.links?.map(({ name, url }, id) => (
									<ListItem key={id}>
										<FooterLink
											onClick={e => handleClick(e, push, url)}
											href={url}
											light
										>
											{name}
										</FooterLink>
									</ListItem>
								))}
							</List>
						)}
						{third && (
							<List>
								{third?.header && (
									<ListItem>
										<TextSectionHeader>{third?.header}</TextSectionHeader>
									</ListItem>
								)}
								{third?.mail && (
									<ListItem>
										<FooterLink href={third?.mail} light>
											{third?.mail}
										</FooterLink>
									</ListItem>
								)}
								{third?.phone && <ListItem>{third?.phone}</ListItem>}
							</List>
						)}
					</ColumnContainer>
				</Wrapper>
			</MaxWidthContainer>
		</FooterSection>
	);
};

export default withTheme(Footer);

const FooterSection = styled.section`
	font-family: ${({ theme }) => theme.typography.fonts.secondary};
	width: auto;
	min-height: ${({ theme }) => theme.spacing[40]};
	display: flex;
	align-items: center;
	justify-content: center;
	z-index: 2;
	padding: ${({ theme }) => `${theme.spacing[8]} 0`};
	background: ${({ theme }) => theme.footer.background};
	flex-direction: column;
	transition: min-height 0.3s ease;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme }) => `${theme.spacing[12]} 0 ${theme.spacing[40]} 0`};
	}
`;

const Wrapper = styled(InnerContainer)`
	display: flex;
	align-items: flex-start;
	justify-content: center;
	margin: 0 auto;
	flex-direction: column;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		justify-content: flex-start;
	}
`;

const LogoWrapperWhite = styled.img`
	width: ${({ theme }) => theme.spacing[36]};
	padding: ${({ theme }) => `0 0 ${theme.spacing[10]} 0`};
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	opacity: 0.7;

	&:hover {
		opacity: 1;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		margin: ${({ theme }) => `0 auto ${theme.spacing.default} 0`};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		margin: ${({ theme }) =>
			`0 auto ${theme.spacing.default} ${theme.spacing.default}`};
	}
`;

const TextSectionHeader = styled(H4)`
	font-family: ${({ theme }) => theme.typography.fonts.secondary};
	color: ${({ theme }) => theme.colors.secondary};
`;

const List = styled(Column)`
	flex-basis: 49%;
	list-style: none;
	padding: ${({ theme }) => `0 ${theme.spacing.default}`};
	display: flex;
	flex-direction: column;
	color: ${theme("colors.grey")};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-basis: 25%;
		margin: 0;
		padding: ${({ theme }) => `0 ${theme.spacing.default}`};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		margin: ${({ theme }) => `0 ${theme.spacing.default}`};
	}
`;

const ListItem = styled.li`
	& + & {
		margin-top: ${({ theme }) => theme.spacing[1.5]};
	}
`;

const FooterLink = styled(Link)`
	font-family: ${({ theme }) => theme.typography.fonts.secondary};
`;
