import styled, { css } from "styled-components";
import withResponsive from "/Shared/helpers/withResponsive";

const PreambleStyles = css`
	font-size: ${props => props.theme.typography.fontSizes.lg};
	font-weight: ${({ theme }) => theme.typography.fontWeights.medium};
	line-height: 150%;
	text-align: left;
	font-family: ${props => props.theme.typography.fonts.primary};

	color: ${({ theme, white }) =>
		white ? theme.colors.white : theme.colors.primary};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: ${props => props.theme.typography.fontSizes.xl};
	}
`;

const Preamble = styled.p`
	${PreambleStyles};
`;

export default withResponsive(Preamble);
