import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";

import { unsubscribe, fetchStartPage } from "api";

import { pageTypes } from "Shared/consts";
import { BaseLayout } from "Layouts";
import { BlockArea, Hero, Seo } from "Components";

import Footer from "Modules/footer/Footer";

const StartWrapper = styled.div``;

let Startpage = ({ theme = {}, pages = [] }) => {
	const [loaded, setLoaded] = useState(false);
	const [page, setPage] = useState({});
	const [blocks, setBlocks] = useState({});

	useEffect(() => {
		fetchStartPage().subscribe(response => {
			const orderNames = response?.items[0]?.blockarea?.itemCodenames;
			const { CASE_PAGE } = pageTypes;

			const flattenLinkedItems = Object.values(response?.linkedItems);
			const blocksToOrder = flattenLinkedItems?.filter(
				block => block?.system?.type !== CASE_PAGE
			);
			const blocksToSet = blocksToOrder.sort((a, b) => {
				const codeNameA = a?._raw?.system?.codename;
				const codeNameB = b?._raw?.system?.codename;
				return orderNames.indexOf(codeNameA) - orderNames.indexOf(codeNameB);
			});

			setPage(response.items[0]);
			setBlocks(blocksToSet);
			setLoaded(true);
		});

		return () => unsubscribe();
	}, []);

	if (!loaded) return false;

	const {
		header = {},
		backgroundimage = {},
		ctabuttontext = {},
		ctabuttonlink = {},
		preamble = {},
	} = page;

	let bgImageToShow = backgroundimage?.value?.length
		? backgroundimage?.value[0].url
		: "";

	const heroProps = {
		header: header?.value,
		preamble: preamble?.value,
		backgroundImage: bgImageToShow,
		cta: {
			text: ctabuttontext?.value,
			link: ctabuttonlink?.value,
		},
	};

	return (
		<>
			<Seo
				header={header?.value}
				preamble={preamble?.value}
				image={bgImageToShow}
			/>

			<BaseLayout pages={pages}>
				<StartWrapper className="page page--start" loaded>
					<Hero {...heroProps} />
					<BlockAreaWrapper>
						<BlockArea blocks={blocks} />
					</BlockAreaWrapper>
					<Footer />
				</StartWrapper>
			</BaseLayout>
		</>
	);
};

export default withTheme(Startpage);

const BlockAreaWrapper = styled.div`
	background: ${({ theme }) =>
		`linear-gradient(180deg, ${theme.colors.white} 30.74%, ${theme.colors.grey} 57.37%)`};
	padding: ${({ theme }) => `${theme.spacing.default} 0 0 0`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme }) => `${theme.spacing[10]} 0 0 0`};
	}
`;
