import styled, { css } from "styled-components";
import withResponsive from "Shared/helpers/withResponsive";

const distance = "10px";

const AnimationWrapper = styled.div`
	opacity: 0;
	transition: ${({ delay = 0, duration = 0.5 }) =>
		`all ${duration}s cubic-bezier(0.42, 0.97, 0.52, 1.49) ${delay}s`};
	transform: translateY(${distance});

	${({ visible }) =>
		visible &&
		css`
			opacity: 1;
			transform: translateY(0);
		`}

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		transform: ${({ type = "slide", direction = "left" }) =>
			type === "slide"
				? direction === "left" || direction === "right"
					? `translateX(${direction === "left" ? `-10px` : `10px`})`
					: `translateY(${direction === "up" ? `10px` : `-10px`})`
				: undefined};

		${({ visible }) =>
			visible &&
			css`
				opacity: 1;
				transform: ${direction =>
					direction === "left" || direction === "right"
						? "translateX(0)"
						: "translateY(0)"};
			`}
	}
`;

export default withResponsive(AnimationWrapper);
