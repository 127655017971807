import React from "react";

import { blockTypes } from "/Shared/consts";
import { Spacer } from "Shared/layout";
import { CTABlock, FullWidthCTABlock, Cases } from "Components";

const BlockArea = ({ blocks = [] }) => {
	if (!Object.values(blocks)?.length) return null;
	return Object.values(blocks).map((block, id) => {
		const blockType = block?.system?.type;
		const isFirst = id === 0;
		return (
			<Spacer key={id} spacing={getSpacing(blockType, isFirst)}>
				<MapBlock type={blockType} model={block} />
			</Spacer>
		);
	});
};

const MapBlock = ({ type, model }) => {
	const { CTA_BLOCK, CTA_BLOCK_FULL_WIDTH, CASES_BLOCK } = blockTypes;
	switch (type) {
		case CTA_BLOCK:
			return <CTABlock {...model} />;
		case CTA_BLOCK_FULL_WIDTH:
			return <FullWidthCTABlock {...model} />;
		case CASES_BLOCK:
			return <Cases {...model} />;
		default:
			return null;
	}
};

const getSpacing = (type = "", isFirst = false) => {
	const { CTA_BLOCK, CTA_BLOCK_FULL_WIDTH, CASES_BLOCK } = blockTypes;
	const defaultSpacing = { base: 2, md: 4 };
	switch (type) {
		case CTA_BLOCK:
		case CASES_BLOCK:
		case CTA_BLOCK_FULL_WIDTH:
			return isFirst ? defaultSpacing : { base: 10, md: 20 };
		default:
			return defaultSpacing;
	}
};

export default BlockArea;
