import { colors } from "../Shared/core";

const pinkTheme = {
	colors: {
		background: "#860F24",
	},
	pageHeader: {
		top: {
			background: {
				from: "#860F24",
				to: "#8d1f32",
			},
		},
	},
	buttons: {
		ghostButton: {
			color: colors.white,
			background: "rgb(255 255 255 / 15%)",
			hover: {
				background: "rgb(255 255 255 / 85%)",
				color: colors.ebonyClay,
			},
		},
	},
	header: {
		background: {
			default: "#860F24",
			scrolled: "#860F24",
		},
	},
};

export default pinkTheme;
