import React, { useEffect, useState, useRef } from "react";
import styled, { withTheme } from "styled-components";

import { useIntersection } from "js/hooks";

import {
	MaxWidthContainer,
	ColumnContainer,
	Column,
	Spacer,
	AnimationWrapper,
} from "Shared/layout";
import {
	Image,
	MultipleChoice,
	LinkedItem,
} from "Shared/helpers/getModelTypes";
import {
	Button,
	H2,
	Paragraph,
	Label,
	Decoration,
	Image as LazyLoadImage,
} from "BaseComponents";

const CTABlock = ({
	header,
	preamble,
	link,
	image,
	label,
	link_text,
	theme,
	show_image_to_the_right,
}) => {
	const [blockVisible, setBlockVisible] = useState(false);
	const [buttonUrl, setButtonUrl] = useState("");
	const blockImage = Image(image);
	const toTheRight = MultipleChoice(show_image_to_the_right) === "show_right";
	const blockRef = useRef();

	const { isVisible: blockIntersection } = useIntersection(blockRef, "150px");

	useEffect(() => {
		LinkedItem(link, setButtonUrl);
	}, [link]);

	useEffect(() => {
		setBlockVisible(blockIntersection);
	}, [blockIntersection]);

	return (
		<MaxWidthContainer>
			<ColumnContainer
				gap={theme.spacing.default}
				justify-content="space-between"
				flex-direction={toTheRight ? "row-reverse" : "row"}
			>
				{blockImage && (
					<BlockImageWrapper
						ref={blockRef}
						visible={blockVisible}
						delay={0.3}
						direction={!toTheRight ? "left" : "right"}
						flex-basis={{
							base: "100%",
							md: `calc(50% - ${theme.spacing.default})`,
						}}
					>
						<LazyLoadImage image={blockImage} />
					</BlockImageWrapper>
				)}
				<Column
					flex-basis={{ base: "100%", md: "50%" }}
					max-width={{ md: theme.spacing[128] }}
					margin="0 auto"
				>
					{label && <Label>{label?.value}</Label>}
					<AnimationWrapper
						ref={blockRef}
						visible={blockVisible}
						delay={0.3}
						direction={toTheRight ? "left" : "right"}
					>
						<H2>{header?.value}</H2>
					</AnimationWrapper>
					<AnimationWrapper
						ref={blockRef}
						visible={blockVisible}
						delay={0.4}
						direction={toTheRight ? "left" : "right"}
						duration={0.4}
					>
						<Spacer>
							<Decoration />
						</Spacer>
						{!!preamble?.value && (
							<Spacer>
								<Paragraph>{preamble?.value}</Paragraph>
							</Spacer>
						)}
					</AnimationWrapper>
					<AnimationWrapper
						ref={blockRef}
						visible={blockVisible}
						delay={0.6}
						direction={toTheRight ? "left" : "right"}
						duration={0.4}
					>
						{link && buttonUrl && (
							<Spacer spacing={{ base: 4, md: 8 }}>
								<Button primary link={buttonUrl}>
									{link_text?.value}
								</Button>
							</Spacer>
						)}
					</AnimationWrapper>
				</Column>
			</ColumnContainer>
		</MaxWidthContainer>
	);
};

export default withTheme(CTABlock);

const BlockImageWrapper = styled(AnimationWrapper)`
	background-image: ${({ background }) => background};
	position: relative;
	max-height: ${({ theme }) => theme.spacing[44]};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		max-height: ${({ theme }) => theme.spacing[80]};
		aspect-ratio: 16/9;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`;
