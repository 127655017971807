const typography = {
	fonts: {
		primary: "Montserrat, sans-serif",
		secondary: "'EXO 2'",
	},
	fontSizes: {
		xxs: "0.625rem",
		xs: "0.75rem",
		sm: "0.875rem",
		md: "1rem",
		lg: "1.125rem",
		xl: "1.5rem",
		"2xl": "1.75rem",
		"3xl": "2rem",
		"4xl": "2.25rem",
		"5xl": "3rem",
		"6xl": "3.5rem",
		"7xl": "4.5rem",
		"8xl": "6rem",
		"9xl": "8rem",
	},
	fontWeights: {
		light: 300,
		book: 350,
		normal: 400,
		medium: 500,
		mediumBold: 600,
		bold: 700,
	},
	lineHeights: {
		base: 1.5,
		3: ".75rem",
		4: "1rem",
		5: "1.25rem",
		6: "1.5rem",
		7: "1.75rem",
		8: "2rem",
		9: "2.25rem",
		10: "2.5rem",
		11: "2.75rem",
		12: "3rem",
		13: "3.25rem",
		14: "3.5rem",
		15: "3.75rem",
		16: "4rem",
	},
	letterSpacings: {
		tighter: "-0.05em",
		tight: "-0.025em",
		normal: "0",
		wide: "0.025em",
		label: "0.03em",
		wider: "0.05em",
		widest: "0.1em",
	},
};

export default typography;
