import React from "react";
import styled, { withTheme } from "styled-components";
import { useParams } from "react-router-dom";

import { MapTheme } from "Shared/helpers";
import Header from "Modules/header/Header";

const BaseLayout = ({
	children,
	theme = {},
	pages = [],
	location = "",
	themeVariant = "",
}) => {
	const { slug } = useParams();

	const themeToShow = themeVariant
		? themeVariant
		: slug === "/" || !slug
		? "theme"
		: themeVariant;

	return (
		<BaseLayoutWrapper>
			<MapTheme theme={themeToShow}>
				<Header location={location} pages={pages} themeVariant={themeVariant} />
				{children}
			</MapTheme>
		</BaseLayoutWrapper>
	);
};

export default withTheme(BaseLayout);

const BaseLayoutWrapper = styled.div``;
