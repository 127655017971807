import styled from "styled-components";
import withResponsive from "shared/helpers/withResponsive";

const ColumnContainer = styled.div`
	display: flex;
	flex-wrap: ${({ noWrap = false }) => (noWrap ? "nowrap" : "wrap")};
	margin-left: ${({ theme, noGutters }) =>
		noGutters ? undefined : `-${theme.spacing.gutterMobile}`};
	margin-right: ${({ theme, noGutters }) =>
		noGutters ? undefined : `-${theme.spacing.gutterMobile}`};
	width: ${({ theme, noGutters }) =>
		noGutters
			? undefined
			: `calc(100% + ${theme.spacing.gutterMobile} * 2)`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		margin-left: ${({ theme, noGutters }) =>
			noGutters ? undefined : `-${theme.spacing.gutter}`};
		margin-right: ${({ theme, noGutters }) =>
			noGutters ? undefined : `-${theme.spacing.gutter}`};
		width: ${({ theme, noGutters }) =>
			noGutters ? undefined : `calc(100% + ${theme.spacing.gutter} * 2)`};
	}
`;

export default withResponsive(ColumnContainer);
