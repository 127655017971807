import React from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/opacity.css";

const Image = ({ image = {}, effect = "opacity", additionalStyles = {} }) => {
	return (
		<LazyLoadImage
			{...image}
			effect={effect}
			threshold={100}
			wrapperProps={{
				style: { ...additionalStyles, height: "100%", width: "100%" },
			}}
		/>
	);
};

export default Image;
