import React from "react";
import styled from "styled-components";

import { Spacer } from "Shared/layout";
import { H2, Paragraph, Button } from "BaseComponents";
import Footer from "Modules/footer/Footer";

const PageWrapper = styled.div`
	background: white;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		background: linear-gradient(#131b1f 3.5vh, white 3.5vh);
	}
`;

const Page404ContentWrapper = styled.div`
	display: flex;
	flex-direction: column-reverse;
	max-width: 1100px;
	margin: 0 auto;
	padding: 0 0 1rem 0;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: 5rem 0;
		flex-direction: row;
	}
`;

const TextWrapper = styled.div`
	padding: 1rem;
`;

const Page404 = ({ url }) => {
	return (
		<PageWrapper className="page">
			<Page404ContentWrapper>
				<TextWrapper>
					<H2 className="header">Sidan kunde inte hittas...</H2>
					<Paragraph type="preamble">Du försökte nå "/{url}"</Paragraph>
					<Spacer>
						<Button primary link="/">
							Tillbaks till startsidan
						</Button>
					</Spacer>
				</TextWrapper>
			</Page404ContentWrapper>
			<Footer />
		</PageWrapper>
	);
};

export default Page404;
