import styled from "styled-components";
import withResponsive from "shared/helpers/withResponsive";

const Spacer = styled.div`
	padding-${({ direction }) => direction || "top"}: ${({ spacing, theme }) =>
	spacing && typeof spacing.base != "undefined"
		? theme.spacing[spacing.base]
		: spacing && typeof spacing == "number"
		? theme.spacing[spacing]
		: theme.spacing.default};

    @media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
        padding-${({ direction }) => direction || "top"}: ${({
	spacing,
	theme,
}) =>
	spacing && typeof spacing.sm != "undefined"
		? theme.spacing[spacing.sm]
		: undefined};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.md}) {
        padding-${({ direction }) => direction || "top"}: ${({
	spacing,
	theme,
}) =>
	spacing && typeof spacing.md != "undefined"
		? theme.spacing[spacing.md]
		: undefined};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
        padding-${({ direction }) => direction || "top"}: ${({
	spacing,
	theme,
}) =>
	spacing && typeof spacing.lg != "undefined"
		? theme.spacing[spacing.lg]
		: undefined};
    }

    @media (min-width: ${({ theme }) => theme.breakpoints.xl}) {
        padding-${({ direction }) => direction || "top"}: ${({
	spacing,
	theme,
}) =>
	spacing && typeof spacing.xl != "undefined"
		? theme.spacing[spacing.xl]
		: undefined};
    }
`;

export default withResponsive(Spacer);
