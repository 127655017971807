import React, { useEffect, useState, useRef } from "react";
import styled, { withTheme } from "styled-components";
import { client } from "Shared/config";
import {
	ColumnContainer,
	Column,
	Spacer,
	AnimationWrapper,
} from "Shared/layout";
import { Image, MultipleChoice } from "Shared/helpers/getModelTypes";
import { Button, H2, H3, Paragraph, Label } from "BaseComponents";
import { useBreakpoint, useIntersection } from "js/hooks";

const FullWidthCTABlock = ({
	header,
	preamble,
	background,
	link,
	image,
	label,
	link_text,
	theme,
	alternative_link,
	show_image_to_the_right,
}) => {
	const [blockVisible, setBlockVisible] = useState(false);
	const [buttonUrl, setButtonUrl] = useState("");
	const blockImage = Image(image);
	const toTheRight = MultipleChoice(show_image_to_the_right) === "show_right";
	const bgColor = MultipleChoice(background);
	const isMediumUp = useBreakpoint("medium", "up");
	const blockRef = useRef();

	const { isVisible: blockIntersection } = useIntersection(blockRef, "150px");

	useEffect(() => {
		setBlockVisible(blockIntersection);
	}, [blockIntersection]);

	useEffect(() => {
		if (!!link?.itemCodenames?.length) {
			client
				.item(link?.itemCodenames[0])
				.depthParameter(1)
				.toObservable()
				.subscribe(response => setButtonUrl(`/${response?.item?.url?.value}`));
		}
	}, [link]);

	const BlockButton = () => {
		return (
			link &&
			(buttonUrl || alternative_link) && (
				<Spacer spacing={{ base: 4, md: 8 }}>
					<Button
						primary
						link={buttonUrl || alternative_link?.value}
						isAlternativeLink={!!alternative_link?.value}
					>
						{link_text?.value}
					</Button>
				</Spacer>
			)
		);
	};

	return (
		<BlockContainer background={bgColor} ref={blockRef}>
			<ColumnContainer
				gap={theme.spacing.default}
				justify-content="space-between"
				flex-direction={toTheRight ? "row-reverse" : "row"}
				width="100%"
				margin="0"
			>
				{blockImage && (
					<BlockImageWrapper
						background={blockImage?.src}
						flex-basis={{
							base: "100%",
							md: `calc(50% - ${theme.spacing.default})`,
						}}
					>
						{!isMediumUp && (
							<AnimationWrapper
								ref={blockRef}
								visible={blockVisible}
								delay={0.3}
								direction={toTheRight ? "left" : "right"}
							>
								<SmallTextWrapper>
									<H3>{header?.value}</H3>
									{!!preamble?.value && (
										<Spacer>
											<Paragraph>{preamble?.value}</Paragraph>
										</Spacer>
									)}
									<BlockButton />
								</SmallTextWrapper>
							</AnimationWrapper>
						)}
					</BlockImageWrapper>
				)}
				{isMediumUp && (
					<Column
						flex-basis={{ base: "100%", md: "50%" }}
						max-width={{ md: theme.spacing[128] }}
						margin="0 auto"
						display="flex"
						flex-direction="column"
						justify-content="center"
						padding={
							toTheRight
								? `0 0 0 ${theme.spacing[10]}`
								: `0 ${theme.spacing[10]} 0 0`
						}
					>
						<AnimationWrapper
							ref={blockRef}
							visible={blockVisible}
							delay={0.3}
							direction={toTheRight ? "left" : "right"}
						>
							{label && <Label>{label?.value}</Label>}
							<H2>{header?.value}</H2>
						</AnimationWrapper>
						<AnimationWrapper
							ref={blockRef}
							visible={blockVisible}
							delay={0.4}
							direction={toTheRight ? "left" : "right"}
						>
							{!!preamble?.value && (
								<Spacer>
									<Paragraph>{preamble?.value}</Paragraph>
								</Spacer>
							)}
						</AnimationWrapper>
						<AnimationWrapper
							ref={blockRef}
							visible={blockVisible}
							delay={0.5}
							direction={toTheRight ? "left" : "right"}
						>
							<BlockButton />
						</AnimationWrapper>
					</Column>
				)}
			</ColumnContainer>
		</BlockContainer>
	);
};

export default withTheme(FullWidthCTABlock);

const BlockContainer = styled.div`
	background: ${({ theme, background }) =>
		background && theme.colors[background]};
`;

const BlockImageWrapper = styled(Column)`
	background-image: ${({ background }) => `url(${background})`};
	position: relative;
	min-height: ${({ theme }) => theme.spacing[44]};
	width: 100%;
	background-repeat: no-repeat;
	background-size: cover;
	padding: ${({ theme }) => `${theme.spacing[10]} ${theme.spacing[8]}`};
	aspect-ratio: 16/9;
	display: flex;
	align-items: center;
	justify-content: center;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: 0;
		width: 47%;
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		width: 50%;
	}

	img {
		object-fit: cover;
		width: 100%;
		height: 100%;
	}
`;

const SmallTextWrapper = styled.div`
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	text-align: center;
	background: rgba(255, 255, 255, 0.3);
	backdrop-filter: ${({ theme }) => `blur(${theme.spacing[1.2]})`};
	padding: ${({ theme }) => theme.spacing[4]};
	border-radius: ${({ theme }) => theme.spacing.default};

	@media (min-width: ${({ theme }) => theme.breakpoints.sm}) {
		padding: ${({ theme }) => theme.spacing[8]};
	}

	${H3} {
		text-align: center;
	}
`;
