import React, { useState, useEffect } from "react";
import styled, { withTheme } from "styled-components";

import { Image, LinkedItem } from "Shared/helpers/getModelTypes";
import {
	MaxWidthContainer,
	ColumnContainer,
	Column,
	Spacer,
} from "Shared/layout";

import {
	Button,
	H2,
	Paragraph,
	Label,
	Decoration,
	Image as LazyLoadImage,
} from "BaseComponents";

const Cases = ({ cases_area = {}, heading = {} }) => {
	const [cases, setCases] = useState([]);

	useEffect(() => {
		const fetchCases = async () => {
			const casesArray = cases_area?.value;
			setCases(
				casesArray?.map(
					({ header, image, link_url, external_link_url, link_text }) => {
						return {
							header: header?.value,
							image: Image(image),
							link: {
								url: link_url,
								externalUrl: external_link_url?.value,
								text: link_text?.value,
							},
						};
					}
				)
			);
		};
		if (!!cases_area?.value?.length) {
			fetchCases();
		}
	}, [cases_area]);
	return (
		<CasesWrapper>
			<MaxWidthContainer>
				<ColumnContainer flex-direction="column" align-items="center">
					<Column>{!!heading?.value && <H2>{heading?.value}</H2>}</Column>
					<Spacer>
						<Decoration />
					</Spacer>
				</ColumnContainer>
				<Spacer>
					<CaseList length={cases?.length}>
						{cases?.length &&
							cases?.map((c, i) => (
								<Case length={cases?.length} key={i} {...c} />
							))}
					</CaseList>
				</Spacer>
			</MaxWidthContainer>
		</CasesWrapper>
	);
};

const Case = withTheme(
	({ header = "", image = {}, link = {}, theme = {}, length = 0 }) => {
		const [buttonUrl, setButtonUrl] = useState(null);
		const [isExternalLink, setIsExternalLink] = useState(false);

		useEffect(() => {
			if (!!link?.url?.itemCodenames?.length) {
				LinkedItem(link?.url, setButtonUrl);
			} else {
				setButtonUrl(link?.externalUrl);
				setIsExternalLink(true);
			}
		}, [link]);

		return (
			<CaseWrapper length={length}>
				<Label>{header}</Label>
				{image && (
					<ImageContainer>
						<LazyLoadImage
							additionalStyles={{ display: "flex", justifyContent: "center" }}
							image={image}
						/>
					</ImageContainer>
				)}
				<Spacer>
					<Button
						isAlternativeLink={isExternalLink}
						isExternal={isExternalLink}
						primary
						link={buttonUrl}
					>
						{link?.text}
					</Button>
				</Spacer>
			</CaseWrapper>
		);
	}
);

export default Cases;

const CasesWrapper = styled.div`
	background: ${({ theme }) => theme.colors.white};
	padding: ${({ theme }) => `${theme.spacing[8]} 0`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme }) => `${theme.spacing[16]} 0`};
	}
`;
const CaseList = styled.ul`
	display: flex;
	gap: ${({ theme }) => theme.spacing.default};
	flex-wrap: nowrap;
	overflow-x: scroll;
	padding: ${({ theme }) => `${theme.spacing.default} 0`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		overflow-x: ${({ length }) => (length > 3 ? "scroll" : "hidden")};
	}
`;

const ImageContainer = styled(Spacer)`
	max-height: ${({ theme }) => theme.spacing[30]};
`;

const CaseWrapper = styled.li`
	background: ${({ theme }) => theme.case.background};
	border-radius: ${({ theme }) => theme.spacing[5]};
	flex-basis: ${({ length }) => (length === 1 ? `100%` : `75%`)};
	flex-grow: 1;
	display: flex;
	flex-direction: column;
	align-items: center;
	transition: all ease-in-out 0.3s;
	padding: ${({ theme }) => `${theme.spacing[8]}`};
	cursor: pointer;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-grow: 0;
		padding: ${({ theme }) => `${theme.spacing[10]}`};
		flex-basis: 25%;
	}

	&:hover {
		background: ${({ theme }) => theme.case.hover.background};
		& * {
			color: ${({ theme }) => theme.case.hover.text};
		}
	}
`;
