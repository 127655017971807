import styled from "styled-components";

export default styled.li`
	color: white;

	& + & {
		margin-left: 1.7rem;

		@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
			margin-left: 4rem;
		}
	}

	& a {
		color: ${props => props.theme.colors.lightGreen};
		text-transform: uppercase;
		text-decoration: none;
		transition: all 0.4s ease-in-out;
		font-family: ${props => props.theme.typography.fonts.primary};
		letter-spacing: 1px;
		font-size: ${props => props.theme.typography.fontSizes.md};
		font-weight: bold;

		@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
			font-weight: normal;
			color: ${props => props.theme.colors.white};
		}

		&:after {
			content: "";
			display: block;
			width: 100%;
			height: 2px;
			background-color: ${props => props.theme.colors.sunglo};
			margin-top: 4px;
			transition: all 0.4s ease-in-out;
		}

		&:hover,
		&:focus {
			color: ${props => props.theme.colors.lightGreen};
			&:after {
				background-color: ${props => props.theme.colors.lightGreen};
			}
		}
	}
`;
