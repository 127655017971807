const spacing = {
	gutter: "0.5rem",
	gutterMobile: "0.5rem",
	siteWidth: "90rem",
	siteWidthNarrow: "64rem",
	siteMargin: "2.5rem",
	siteMarginTablet: "1.5rem",
	siteMarginMobile: "0.75rem",
	desktopNavigationWidth: "14rem",
	default: "1rem",
	0: "0",
	0.5: "0.125rem",
	1: "0.25rem",
	1.2: "0.313rem",
	1.5: "0.375rem",
	2: "0.5rem",
	2.5: "0.625rem",
	3: "0.75rem",
	3.5: "0.875rem",
	4: "1rem",
	4.5: "1.125rem",
	5: "1.25rem",
	5.5: "1.375rem",
	6: "1.5rem",
	7: "1.75rem",
	8: "2rem",
	9: "2.25rem",
	10: "2.5rem",
	12: "3rem",
	13: "3.25rem",
	14: "3.5rem",
	15: "3.75rem",
	16: "4rem",
	17: "4.375rem",
	18: "4.5rem",
	19: "4.6875rem",
	20: "5rem",
	21: "5.25rem",
	22: "5.5rem",
	24: "6rem",
	28: "7rem",
	30: "7.5rem",
	32: "8rem",
	36: "9rem",
	37: "9.25rem",
	40: "10rem",
	44: "11rem",
	48: "12rem",
	52: "13rem",
	56: "14rem",
	60: "15rem",
	64: "16rem",
	72: "18rem",
	80: "20rem",
	88: "22rem",
	92: "23rem",
	96: "24rem",
	128: "32rem",
	144: "36rem",
	160: "40rem",
	200: "47rem",
	212: "53rem",
	256: "64rem",
	270: "75rem",
};
export default spacing;
