import styled from "styled-components";

const InnerContainer = styled.div`
	padding: ${({ theme, noGutters }) =>
		noGutters ? undefined : `0 ${theme.spacing.siteMarginMobile}`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding: ${({ theme, noGutters }) =>
			noGutters ? undefined : `0 ${theme.spacing.siteMarginTablet}`};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		padding: ${({ theme, noGutters }) =>
			noGutters ? undefined : `0 ${theme.spacing.siteMargin}`};
	}

	& & {
		padding: 0;
	}
	max-width: ${({ theme, narrow, wide }) =>
		narrow
			? theme.spacing[200]
			: wide
			? theme.spacing[270]
			: theme.spacing[256]};
	margin: ${({ center }) => (center ? "0 auto" : "auto")};
`;

export default InnerContainer;
