import styled from "styled-components";
import withResponsive from "shared/helpers/withResponsive";

const Column = styled.div`
	display: block;
	padding-left: ${({ theme, noGutters = false }) =>
		noGutters ? undefined : theme.spacing.gutterMobile};
	padding-right: ${({ theme, noGutters = false }) =>
		noGutters ? undefined : theme.spacing.gutterMobile};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		padding-left: ${({ theme, noGutters = false }) =>
			noGutters ? undefined : theme.spacing.gutter};
		padding-right: ${({ theme, noGutters = false }) =>
			noGutters ? undefined : theme.spacing.gutter};
	}
`;

export default withResponsive(Column);
