const Image = item => {
	return {
		src: item?.value[0]?.url,
		size: item?.value[0]?.size,
		type: item?.value[0]?.type,
		name: item?.value[0]?.name,
		description: item?.value[0]?.description || "",
	};
};

export default Image;
