const handleClick = (e, push, link = "", alternative) => {
	e.preventDefault();
	if (alternative) {
		window.location.href = alternative;
	} else if (link) {
		push(link);
	}
};

export default handleClick;
