import React from "react";
import { ThemeProvider } from "styled-components";

import { colors, typography, spacing, breakpoints } from "../Shared/core";

export const theme = {
	colors: {
		primary: colors.ebonyClay,
		secondary: colors.nightShadz,
		pink: colors.sunglo,
		blue: colors.allPorts,
		grey: colors.gallery,
		lightGrey: colors.concrete,
		black: colors.black,
		white: colors.white,
		transparent: "transparent",
		background: colors.concrete,
		label: colors.darkGrey,
	},
	link: {
		color: colors.ebonyClay,
		hover: colors.primary,
		light: {
			color: colors.concrete,
			hover: colors.silver,
		},
	},
	pageHeader: {
		top: {
			background: {
				from: "#024c71",
				to: colors.allPorts,
			},
		},
	},
	case: {
		background: colors.porcelain,
		hover: {
			background: colors.ebonyClay,
			text: colors.white,
		},
	},
	header: {
		background: {
			default: "rgba(255, 255, 255, 0.3)",
			scrolled: "rgba(255, 255, 255, 0.78)",
		},
	},
	footer: {
		background: colors.bunker,
	},
	buttons: {
		primary: {
			color: colors.ebonyClay,
			background: "transparent",
			border: colors.sunglo,
			hover: {
				background: colors.nightShadz,
				color: colors.concrete,
				border: colors.nightShadz,
			},
		},
		secondary: {
			color: colors.concrete,
			background: colors.ebonyClay,
			border: colors.ebonyClay,
			hover: {
				background: colors.ebonyClayDarker10,
				color: colors.concrete,
				border: colors.ebonyClayDarker10,
			},
		},
		white: {
			color: colors.ebonyClay,
			background: colors.white,
			border: colors.white,
			hover: {
				background: colors.concrete,
				color: colors.black,
				border: colors.concrete,
			},
		},
		disabled: {
			color: colors.trout,
			background: colors.grey,
			border: colors.concrete,
		},
		ghostButton: {
			color: colors.ebonyClay,
			background: "rgb(255 255 255 / 15%)",
			hover: {
				background: "rgb(255 255 255 / 85%)",
				color: colors.ebonyClay,
			},
		},
	},
	breakpoints,
	typography,
	spacing,
};

const Theme = ({ children }) => (
	<ThemeProvider theme={theme}>{children}</ThemeProvider>
);

export default Theme;
