import React, { useEffect, useState } from "react";
import styled, { withTheme } from "styled-components";
import { useHistory, useParams } from "react-router-dom";
import { useMedia } from "react-use";
import Headroom from "react-headroom";

import { unsubscribe, fetchSettingsPage } from "api";
import { useBreakpoint } from "js/hooks";

import { ColumnContainer, Column, InnerContainer } from "Shared/layout";
import { Burger, GhostButton } from "BaseComponents";

import Logo from "/Images/full-logo-blue.png";
import LogoWhite from "/Images/full-logo-white.png";
import SmallLogo from "/Images/half-logo.png";

const Header = ({ pages = [], theme = {}, location, themeVariant }) => {
	const [headerButtons, setHeaderButtons] = useState([]);

	const isMediumUp = useMedia(`(min-width: ${theme.breakpoints.md})`, true);
	const { push } = useHistory();
	const { slug } = useParams();

	const themeToShow = themeVariant
		? themeVariant
		: slug === "/" || !slug
		? "theme"
		: themeVariant;

	const handleGhostButtonClick = (url, alternative) => {
		if (alternative) {
			window.location.href = alternative;
		} else {
			push(url);
		}
	};

	useEffect(() => {
		fetchSettingsPage().subscribe(({ firstItem }) => {
			const firstButton = {
				name: firstItem?.first_button___text?.value,
				url: firstItem?.first_button___link?.value?.length
					? `/${firstItem?.first_button___link?.value[0]?.url?.value}`
					: "",
				alternative: firstItem?.first_button___alternative_link?.value,
			};
			const secondButton = {
				name: firstItem?.second_button___text?.value,
				url: firstItem?.second_button___link?.value?.length
					? `/${firstItem?.second_button___link?.value[0]?.url?.value}`
					: "",
				alternative: firstItem?.second_button___alternative_link?.value,
			};
			setHeaderButtons([firstButton, secondButton]);
		});

		return () => unsubscribe();
	}, []);

	return (
		<Headroom upTolerance={5}>
			<HeaderElm className="header">
				<HeaderWrapper wide>
					<ColumnContainer
						display="flex"
						flex-direction="row"
						align-items="center"
						justify-content="space-between"
					>
						<Column display="flex" align-items="center">
							<LogoWrapperWhite
								src={
									isMediumUp
										? themeToShow === "theme"
											? Logo
											: LogoWhite
										: SmallLogo
								}
								onClick={() => push("/")}
							/>
						</Column>
						<Column
							display="flex"
							align-items="center"
							gap={{ md: theme.spacing[16] }}
						>
							{useBreakpoint("medium", "up") && (
								<ButtonWrapper>
									{headerButtons?.map(
										({ name = "", url = "", alternative }, index) => {
											if (!name || (!url && !alternative)) return null;
											return (
												<GhostButton
													onClick={() =>
														handleGhostButtonClick(url, alternative)
													}
													key={index}
												>
													{name}
												</GhostButton>
											);
										}
									)}
								</ButtonWrapper>
							)}
							<Burger pages={pages} />
						</Column>
					</ColumnContainer>
				</HeaderWrapper>
			</HeaderElm>
		</Headroom>
	);
};

export default withTheme(Header);

const HeaderElm = styled.section`
	width: 100%;
	display: flex;
	align-items: center;
	justify-content: space-between;
	padding: ${({ theme }) => `0 ${theme.spacing.default}`};
	width: 100%;
	z-index: 2;
	padding: 0;
	transition: all 0.2s ease;
	background: ${({ theme }) => theme.header.background.default};
	backdrop-filter: ${({ theme }) => `blur(${theme.spacing[1.2]})`};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		flex-direction: row;
		min-height: ${({ theme }) => theme.spacing[16]};
	}

	.headroom--unpinned,
	.headroom--scrolled & {
		background: ${({ theme }) => theme.header.background.scrolled};
	}
`;

const HeaderWrapper = styled(InnerContainer)`
	display: flex;
	align-items: center;
	justify-content: space-between;
	width: 100%;
	margin: 0 auto;
	flex-direction: row;
	padding-top: ${({ theme }) => theme.spacing[2]};
	padding-bottom: ${({ theme }) => theme.spacing[2]};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		justify-content: flex-start;
		flex-direction: row;
	}
`;

const LogoWrapperWhite = styled.img`
	width: ${({ theme }) => theme.spacing[10]};
	transition: all 0.3s ease;
	cursor: pointer;

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		width: ${({ theme }) => theme.spacing[30]};

		.headroom--unpinned,
		.headroom--scrolled & {
			background: transparent;
		}
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		width: ${({ theme }) => theme.spacing[40]};
	}
`;

const ButtonWrapper = styled.div`
	display: flex;
	gap: ${({ theme }) => theme.spacing[8]};
	align-items: center;
`;
