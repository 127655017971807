import { client } from "Shared/config";

const LinkedItem = async (item, effect) => {
	if (!item?.itemCodenames?.length) return "";
	client
		.item(item?.itemCodenames[0])
		.depthParameter(1)
		.toObservable()
		.subscribe(response => {
			const url = `/${response?.item?.url?.value}`;
			if (effect) {
				effect(url);
			} else {
				return url;
			}
		});
};

export default LinkedItem;
