import React, { useEffect, useState, useRef } from "react";
import styled, { withTheme, css } from "styled-components";
import { useIntersection } from "js/hooks";

import {
	MaxWidthContainer,
	ColumnContainer,
	Column,
	Spacer,
	AnimationWrapper,
} from "/Shared/layout";
import { H1, H4, Button, Decoration } from "/BaseComponents";

const Hero = ({
	header = "",
	backgroundImage = "",
	preamble = "",
	cta = {},
	theme = {},
}) => {
	const [blockVisible, setBlockVisible] = useState(false);
	const blockRef = useRef();

	const { isVisible: blockIntersection } = useIntersection(blockRef, "150px");

	useEffect(() => {
		setBlockVisible(blockIntersection);
	}, [blockIntersection]);

	return (
		<HeroWrapper ref={blockRef} backgroundImage={backgroundImage}>
			<MaxWidthContainer>
				<ColumnContainer align-items={{ base: "center", md: "flex-start" }}>
					<Column
						display="flex"
						flex-direction="column"
						align-items={{ base: "center", md: "flex-start" }}
						margin={{ base: "0 auto", md: "0" }}
						flex-basis={{ sm: "90%", lg: "50%" }}
						padding-left={{
							base: 0,
							md: theme.spacing[12],
							lg: theme.spacing[20],
						}}
					>
						<AnimationWrapper
							ref={blockRef}
							visible={blockVisible}
							delay={0.1}
							direction="bottom"
						>
							<H1 text-align={{ base: "center", md: "left" }}>{header}</H1>
						</AnimationWrapper>
						<AnimationWrapper
							ref={blockRef}
							visible={blockVisible}
							delay={0.6}
							display="flex"
							flex-direction="column"
							align-items={{ base: "center", md: "flex-start" }}
							direction="left"
						>
							<Spacer spacing={{ md: 8 }}>
								<Decoration big />
							</Spacer>
						</AnimationWrapper>
						{preamble && (
							<AnimationWrapper
								ref={blockRef}
								visible={blockVisible}
								delay={0.3}
								direction="up"
							>
								<Spacer spacing={{ md: 8 }}>
									<TextSectionHeader>{preamble}</TextSectionHeader>
								</Spacer>
							</AnimationWrapper>
						)}

						<Spacer spacing={{ md: preamble ? 4 : 8 }}>
							<HeroButtonWrapper>
								{cta?.link && cta?.text && (
									<AnimationWrapper
										ref={blockRef}
										visible={blockVisible}
										delay={0.4}
										direction="up"
									>
										<Button link={cta.link}>{cta.text}</Button>
									</AnimationWrapper>
								)}
							</HeroButtonWrapper>
						</Spacer>
					</Column>
				</ColumnContainer>
			</MaxWidthContainer>
		</HeroWrapper>
	);
};

export default withTheme(Hero);

const HeroWrapper = styled.div`
	min-height: 55vh;
	width: 100%;
	background: ${({ theme }) => theme.footer.background};
	align-items: center;
	justify-content: center;
	display: flex;
	position: relative;
	background-position: center;
	background-repeat: no-repeat;
	background-size: cover;
	flex-direction: column;
	padding: ${({ theme }) => `${theme.spacing[24]} 0 ${theme.spacing[16]} 0`};

	${({ backgroundImage }) =>
		backgroundImage.length &&
		css`
			background-image: url(${backgroundImage});
		`}

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		min-height: 75vh;
		background-attachment: fixed;
		padding: ${({ theme }) => `${theme.spacing[16]} 0`};

		${({ backgroundImage }) =>
			backgroundImage.length &&
			css`
				background-image: url(${backgroundImage});
			`}

		&:after {
			content: "";
			width: 100%;
			position: absolute;
			height: 13%;
			background: ${({ theme }) => theme.colors.white};
			clip-path: polygon(0% 0%, 100% 90%, 100% 100%, 0% 100%);
			bottom: -4px;
		}
	}
`;

const TextSectionHeader = styled(H4)`
	font-family: ${({ theme }) => theme.typography.fonts.secondary};
	color: ${({ theme }) => theme.colors.secondary};
`;

const HeroButtonWrapper = styled.div`
	display: flex;
	justify-content: flex-start;
`;
