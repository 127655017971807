import styled, { css } from "styled-components";
import { ifProp } from "styled-tools";
import withResponsive from "/Shared/helpers/withResponsive";

const baseStyles = css`
	line-height: 150%;
	font-family: ${({ theme }) => theme.typography.fonts.primary};
	font-weight: bold;
	color: ${({ theme, secondary }) =>
		secondary ? theme.colors.secondary : theme.colors.primary};

	text-align: ${ifProp("center", "center", "left")};
`;

export const HeadingDisplayStyles = css`
	${baseStyles};

	font-size: ${({ theme }) => theme.typography.fontSizes["4xl"]};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: ${({ theme }) => theme.typography.fontSizes["5xl"]};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		font-size: ${({ theme }) => theme.typography.fontSizes["6xl"]};
	}
`;

export const H1Styles = css`
	${baseStyles};

	font-size: ${({ theme }) => theme.typography.fontSizes["3xl"]};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: ${({ theme }) => theme.typography.fontSizes["4xl"]};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		font-size: ${({ theme }) => theme.typography.fontSizes["5xl"]};
	}
`;

export const H2Styles = css`
	${baseStyles};
	font-size: ${({ theme }) => theme.typography.fontSizes["2xl"]};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: ${({ theme }) => theme.typography.fontSizes["3xl"]};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		font-size: ${({ theme }) => theme.typography.fontSizes["4xl"]};
	}
`;

export const H3Styles = css`
	${baseStyles};
	font-size: ${({ theme }) => theme.typography.fontSizes.xl};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: ${({ theme }) => theme.typography.fontSizes["2xl"]};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		font-size: ${({ theme }) => theme.typography.fontSizes["3xl"]};
	}
`;

export const H4Styles = css`
	${baseStyles};
	font-size: ${({ theme }) => theme.typography.fontSizes.md};

	@media (min-width: ${({ theme }) => theme.breakpoints.md}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.lg};
	}

	@media (min-width: ${({ theme }) => theme.breakpoints.lg}) {
		font-size: ${({ theme }) => theme.typography.fontSizes.lg};
	}
`;
export const HeadingDisplay = styled.h1`
	${HeadingDisplayStyles};
`;

export const H1 = withResponsive(styled.h1`
	${H1Styles};
`);

export const H2 = styled.h2`
	${H2Styles};
`;

export const H3 = styled.h3`
	${H3Styles};
`;

export const H4 = styled.h4`
	${H4Styles};
`;

const Heading = {
	HeadingDisplayStyles,
	H1Styles,
	H2Styles,
	H3Styles,
	H4Styles,
	HeadingDisplay,
	H1,
	H2,
	H3,
	H4,
};

export default Heading;
