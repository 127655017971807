import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { CSSTransition, TransitionGroup } from "react-transition-group";
import { Subject } from "rxjs";
import { takeUntil } from "rxjs/operators";

import { client } from "./Shared/config";
import GlobalStyle from "./Shared/globalStyle";
import { ScrollToTop } from "BaseComponents";
import { MapTheme } from "Shared/helpers";

import "./App.css";
import Theme from "./Themes/theme";

import StartPage from "./Pages/Startpage";
import StandardPage from "./Pages/Standardpage";
import Page404 from "./Pages/Page404";

let unsubscribeSubject = new Subject();

const App = () => {
	const [pages, setPages] = useState([]);

	useEffect(() => {
		fetchPages();

		return () => unsubscribe();
	}, []);

	let fetchPages = () => {
		client
			.items()
			.type("standardpage")
			.depthParameter(1)
			.toObservable()
			.pipe(takeUntil(unsubscribeSubject))
			.subscribe(response => {
				const filteredPages = filterPages(response?.items);
				const sortedPages = filteredPages.sort(
					(a, b) => a.menuorder.value - b.menuorder.value
				);
				setPages(sortedPages);
			});
	};

	let filterPages = items => {
		return items.filter(item => {
			return item?.showinmenu?.value?.length;
		});
	};

	let unsubscribe = () => {
		unsubscribeSubject.next();
		unsubscribeSubject.complete();
	};

	return (
		<Theme>
			<AppWrapper>
				<Router>
					<ScrollToTop />
					<GlobalStyle />
					<div className="App__content">
						<Route
							render={({ location }) => (
								<TransitionGroup>
									<CSSTransition
										key={location.key}
										timeout={300}
										classNames="fade"
									>
										<Switch location={location}>
											<Route exact path="/">
												<StartPage pages={pages} />
											</Route>
											<Route path="/:slug">
												<MapTheme theme="blue">
													<StandardPage pages={pages} />
												</MapTheme>
											</Route>
										</Switch>
									</CSSTransition>
								</TransitionGroup>
							)}
						/>
					</div>
				</Router>
			</AppWrapper>
		</Theme>
	);
};

export default App;

const AppWrapper = styled.div``;
